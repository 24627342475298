import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";
import { paramCase, sentenceCase } from "change-case";
import get from "lodash/get";
import Box from "../../base/Box";
import PageBanner from "../../sections/PageBanner";
import Section from "../Section";
import MarkdownToHtml from "../MarkdownToHtml";
import ActionButtons from "../ActionButtons";
import ContactForm from "../ContactForm/ContactForm";
import Modal from "../Modal";
import CareersContainer from "../../containers/CareersContainer";
// import SEOComponent from "../SEOComponent";

const OpeningsLayout = ({ pageContext }) => {
  const {
    openingsData: { id },
  } = pageContext;
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const theme = useContext(ThemeContext);
  const positionHeaderStyle = {
    fontSize: 0,
    fontWeight: "bold",
    letterSpacing: 4,
    mb: 1,
    textTransform: "uppercase",
  };
  const positionDetailStyle = {
    fontSize: 3,
    fontWeight: "light",
    letterSpacing: 1,
    lineHeight: 2,
    mb: 4,
  };

  return (
    <CareersContainer>
      {(careersData, openingsData) => {
        const opening = openingsData.find((o) => paramCase(o.id) === id);
        const title = `Everestek | ${get(opening, "title")}`;
        const description = get(opening, "brief.content");
        return (
          opening && (
            <>
              <PageBanner
                bannerContent={{
                  heading: opening.brief.heading,
                }}
                containerSx={{
                  mb: "auto",
                  pb: [5, null, null, null, 2],
                  position: "relative",
                  pt: [5, null, null, null, 6],
                }}
                contentSx={{
                  ".md_heading": {
                    fontSize: 5,
                    fontWeight: "bold",
                    mb: 3,
                  },
                  fontSize: 2,
                  h2: {
                    ...theme.variants.markdown.h1,
                    color: "primary",
                  },
                  h3: {
                    color: "grays.2",
                  },
                  p: {
                    display: "none",
                  },
                  width: ["100%", null, null, "60%"],
                }}
                sx={{
                  bg: "grays.0",
                }}
              />

              <Section
                containerSx={{
                  display: "flex",
                  flexDirection: ["column", null, null, "row"],
                }}
              >
                <Box sx={{ width: ["100%", null, null, "65%"] }}>
                  <MarkdownToHtml
                    contentSx={{
                      h2: {
                        display: "none",
                      },
                      h3: {
                        display: "none",
                      },
                    }}
                    source={opening.brief.content}
                  />
                </Box>
                <Box
                  sx={{
                    alignItems: ["center", null, null, "flex-end"],
                    display: "flex",
                    flexDirection: "column",
                    // my: "auto",
                    width: ["100%", null, null, "35%"],
                  }}
                >
                  <Box
                    sx={{
                      bg: "primary",
                      color: "white",
                      display: "flex",
                      flexDirection: "column",
                      mt: [4, null, null, `calc(${theme.space[6]} * -3)`],
                      p: 5,
                      width: ["100%", null, null, "90%"],
                    }}
                  >
                    {Object.entries(opening.summary).map(([title, details]) => (
                      <>
                        <Box sx={positionHeaderStyle}>{`${sentenceCase(
                          title
                        )}: `}</Box>
                        <Box sx={positionDetailStyle}>{details}</Box>
                      </>
                    ))}
                    <ActionButtons
                      buttons={[
                        {
                          buttonLabel: "Apply Now",
                          buttonType: "secondary",
                          customEvent: () => toggleModal(),
                          externalLink: false,
                          hideButton: false,
                          simpleLink: false,
                        },
                      ]}
                    />
                  </Box>
                </Box>
              </Section>
              {(opening.jobRole || opening.details) && (
                <Section
                  containerSx={{
                    display: "flex",
                    flexDirection: ["column", null, null, "row"],
                  }}
                  sx={{ pt: 0 }}
                >
                  <Box
                    sx={{
                      width: [null, null, null, "50%"],
                    }}
                  >
                    <MarkdownToHtml
                      contentSx={{
                        h2: {
                          color: "primary",
                        },
                        pr: 2,
                        ul: {
                          li: {
                            "::marker": {
                              color: "primary",
                            },
                            mb: 3,
                          },
                        },
                      }}
                      source={opening.details}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: [null, null, null, "50%"],
                    }}
                  >
                    <MarkdownToHtml
                      contentSx={{
                        h2: {
                          color: "primary",
                        },
                        ul: {
                          li: {
                            "::marker": {
                              color: "primary",
                            },
                            mb: 3,
                          },
                        },
                      }}
                      source={opening.jobRole}
                    />
                  </Box>
                </Section>
              )}
              <Section sx={{ pt: 0 }}>
                <ActionButtons
                  buttons={[
                    {
                      buttonLabel: "Apply Now",
                      buttonType: "primary",
                      customEvent: () => toggleModal(),
                      externalLink: false,
                      hideButton: false,
                      // buttonPath: "/contact",
                      simpleLink: false,
                    },
                    {
                      buttonLabel: "Return to Career Listings",
                      buttonPath: "/careers/#openings",
                      buttonType: "primaryLink",
                      externalLink: false,
                      hideButton: false,
                      simpleLink: false,
                    },
                  ]}
                  wrapperSx={{ flexWrap: "wrap" }}
                />
              </Section>

              <Modal
                isOpen={showModal}
                onClose={toggleModal}
                style={{
                  content: {
                    height: "auto",
                  },
                }}
                title="Send Us Your Resume"
              >
                <ContactForm AllowFile />
              </Modal>
            </>
          )
        );
      }}
    </CareersContainer>
  );
};

OpeningsLayout.propTypes = {
  pageContext: PropTypes.shape({
    openingsData: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default OpeningsLayout;
